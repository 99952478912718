import { useState, useEffect } from "react";
import dayjs from "dayjs";

export default function ScheduleList({
  centre,
  isLoaded,
  schedules,
  loadSchedule,
  viewSchedule,
  mergeSchedule,
  archiveSchedule,
  confirmDeleteSchedule,
}) {
  const [scheduleArray, setScheduleArray] = useState([]);

  useEffect(() => {
    setScheduleArray(schedules);
  }, [schedules]);

  useEffect(() => {
    !centre && setScheduleArray([]);
  }, [centre]);

  const scheduleList = scheduleArray?.map((schedule) => {
    return (
      <tr key={schedule._id}>
        <td
          className={
            schedule.checked_out
              ? "schedule-checked-out"
              : "schedule-checked-in"
          }
        >
          {" "}
        </td>
        <td className="schedule-table-centred-column">{schedule.centre}</td>
        <td>{schedule.schedule_name}</td>
        <td className="centered-column">
          {schedule.is_archive ? "Archive" : "Working"}
        </td>
        <td className="centered-column">
          {schedule.schedule_classlist_min_date &&
            dayjs(schedule.schedule_classlist_min_date).format("DD/MM/YYYY")}
        </td>
        <td className="centered-column">
          {schedule.schedule_classlist_min_date &&
            dayjs(schedule.schedule_classlist_max_date).format("DD/MM/YYYY")}
        </td>
        <td className="numeric-column class-count-column">
          {schedule.schedule_classlist_count}
        </td>
        <td className="numeric-column">
          {schedule.schedule_classlist_published}
        </td>
        <td className="centered-column">
          {dayjs(schedule.update_date).format("DD/MM/YYYY HH:mm")}
        </td>
        <td>{schedule.schedule_author}</td>
        <td className="centered-column">
          {dayjs(schedule.createdAt).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="schedule-table-centred-column">
          <>
            <button
              className="schedule-list-action-button"
              onClick={(event) => viewSchedule(schedule._id)}
            >
              View
            </button>
            {!isLoaded && (
              <button
                className="schedule-list-action-button"
                onClick={(event) => loadSchedule(schedule._id)}
              >
                Load
              </button>
            )}
            {isLoaded && (
              <button
                className="schedule-list-action-button"
                onClick={(event) => mergeSchedule(schedule._id, null, true)}
              >
                Merge
              </button>
            )}
            <button
              className="schedule-list-action-button"
              onClick={() => archiveSchedule(schedule._id)}
            >
              Archive
            </button>
            <button
              className="schedule-list-action-button"
              onClick={() => confirmDeleteSchedule(false, schedule._id)}
            >
              Delete
            </button>
          </>
        </td>
      </tr>
    );
  });
  return (
    <>
      <div className="schedule-button-div">
        <div className="schedule-button-section-left-div">
          <h2>
            Working schedules
            {centre && ` - ${centre.centre_code}`}
          </h2>
        </div>
      </div>
      <table className="schedule-table">
        <thead>
          <tr>
            <th></th>
            <th>Centre</th>
            <th>Name</th>
            <th>Status</th>
            <th>From</th>
            <th>To</th>
            <th>Classes</th>
            <th>Pub.</th>
            <th>Last updated</th>
            <th>Updated by</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{scheduleList}</tbody>
      </table>
    </>
  );
}
