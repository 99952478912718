import { useState } from "react";
import Select from "react-select";
import useTimezone from "../../auth/useTimezone";

import dayjs from "dayjs";

export default function DateTests() {
  const [selectedTimezone, setSelectedTimezone] = useState();
  const timezone = useTimezone();

  const timezoneOptions = [
    { value: "Asia/Hong_Kong", label: "Hong Kong" },
    { value: "Asia/Bangkok", label: "Thailand" },
    { value: "Asia/Tokyo", label: "Japan" },
    { value: "Asia/Seoul", label: "Korea" },
    { value: "Asia/Kuala_Lumpur", label: "Malaysia" },
    { value: "Asia/Singapore", label: "Singapore" },
    { value: "Asia/Taipei", label: "Taiwan" },
    { value: "America/Mexico_City", label: "Mexico" },
    { value: "Europe/Lisbon", label: "Portugal" },
    { value: "Europe/Kiev", label: "Ukraine" },
    { value: "Asia/Saigon", label: "Vietnam" },
    { value: "Asia/Jakarta", label: "Indonesia" },
    { value: "Europe/Paris", label: "France" },
    { value: "Europe/Rome", label: "Italy" },
    { value: "Europe/Madrid", label: "Spain" },
    { value: "Asia/Rangoon", label: "Myanmar" },
    { value: "Asia/Tbilisi", label: "Georgia" },
    { value: "Asia/Amman", label: "Jordan" },
    { value: "Africa/Algiers", label: "Algeria" },
    { value: "Africa/Casablanca", label: "Morocco" },
    { value: "Africa/Tunis", label: "Tunisia" },
    { value: "Africa/Cairo", label: "Egypt" },
    { value: "Europe/Warsaw", label: "Poland" },
    { value: "Asia/Dhaka", label: "Bangladesh" },
    { value: "Asia/Calcutta", label: "India" },
    { value: "Asia/Colombo", label: "Sri Lanka" },
    { value: "Europe/Bucharest", label: "Romania" },
    { value: "Asia/Dubai", label: "Mauritius" },
    { value: "", label: "Mozambique" },
    { value: "", label: "Senegal" },
    { value: "Asia/Qatar", label: "Qatar" },
    { value: "Asia/Dubai", label: "United Arab Emirates" },
    { value: "Asia/Bahrain", label: "Bahrain" },
    { value: "Asia/Kuwait", label: "Kuwait" },
    { value: "Asia/Riyadh", label: "Saudi Arabia" },
    { value: "America/Santiago", label: "Chile" },
    { value: "America/Bogota", label: "Colombia" },
    { value: "America/Caracas", label: "Venezuela" },
  ];

  return (
    <div>
      <h2>Time zone tests</h2>
      <div>
        <Select
          className="schedule-form-strand-selector"
          value={timezoneOptions.find(
            (option) => option.value === selectedTimezone
          )}
          placeholder="Select time zone ..."
          isSearchable
          options={timezoneOptions}
          onChange={(data) => setSelectedTimezone(data?.value)}
        />
        <p>
          Selected time zone date and time:{" "}
          {selectedTimezone &&
            dayjs(new Date())
              .tz(selectedTimezone)
              .format("DD.MM.YYYY HH:mm:ss")}
        </p>
        <p>
          Default date and time:{" "}
          {dayjs(new Date()).tz(timezone).format("DD.MM.YYYY HH:mm:ss")} (
          {timezone})
        </p>
      </div>
    </div>
  );
}
