import React from "react";
import t2_logo from "../schedule/images/t2_logo.png";

export default function LoginHeader() {
  return (
    <header>
      <div className="header-title-div">
        <img src={t2_logo} alt="T2" className="t2-logo" />
      </div>
    </header>
  );
}
