import { useState } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import News from "../components/schedule/News";
import NewModal from "../components/schedule/NewModal";
import { useUser } from "./useUser";

export const PrivateRoute = () => {
  const [newModalContent, setNewModalContent] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const user = useUser();
  const location = useLocation();
  // console.log(location);

  function showNewsModal() {
    setNewModalContent([<News setShowNewModal={setShowNewModal} />, true]);
    setShowNewModal(true);
  }

  return user ? (
    <>
      <Header showNewsModal={showNewsModal} />
      <main>
        <Outlet />
      </main>
      <Footer />
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </>
  ) : (
    <Navigate to="/login" state={location.pathname} />
  );
};
