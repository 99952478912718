import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import fetchData from "./scripts/fetchData.js";
import useTimezone from "../../auth/useTimezone.js";
import dayjs from "dayjs";

export default function News({ setShowNewModal }) {
  const [newsIndex, setNewsIndex] = useState(0);
  const [selectedNews, setSelectedNews] = useState();
  const [newsArray, setNewsArray] = useState([]);
  const timezone = useTimezone();

  const getNews = async () => {
    try {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/news`;
      const response = await fetchData(url, "GET");
      setNewsArray(response);
      setSelectedNews(response[0]);
    } catch (error) {
      console.error("Failed to fetch news:", error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    if (newsArray.length > 0) {
      setSelectedNews(newsArray[newsIndex]);
    }
  }, [newsIndex, newsArray]);

  const newsContent = `
      <div>
        <h4>${selectedNews?.intro_title}</h4>
        <div className="news-info-div">
          <div className="news-info-left-div">
            ${dayjs(selectedNews?.news_date)
              .tz(timezone)
              .format("DD/MM/YYYY HH:mm")}
          </div>
        </div>
        <p>${selectedNews?.intro_text}</p>
        <div>
          ${selectedNews?.content
            .map((contentSection, index) => {
              return `
              <div key=${index}>
                ${contentSection.category}:
                <ul className="news-list">
                  ${contentSection.items
                    .map((item, itemIndex) => {
                      return `<li key=${itemIndex}>${item}</li>`;
                    })
                    .join("")}
                </ul>
              </div>
            `;
            })
            .join("")}
        </div>
      </div>
    `;

  return (
    <div className="news-div">
      <h2>News and updates</h2>
      <div
        id="newsContent"
        className="news-content"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsContent) }}
      />
      <div>Please report bugs or usability issues!</div>
      <div className="news-button-div">
        <button
          className={`schedule-form-button ${
            newsIndex === 0 ? "disabled" : undefined
          }`}
          onClick={() => setNewsIndex(newsIndex - 1)}
          disabled={newsIndex === 0}
        >
          Newer
        </button>
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Close
        </button>
        <button
          className={`schedule-form-button ${
            newsIndex === newsArray.length - 1 ? "disabled" : undefined
          }`}
          onClick={() => setNewsIndex(newsIndex + 1)}
          disabled={newsIndex === newsArray.length - 1}
        >
          Older
        </button>
      </div>
    </div>
  );
}
