export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="footer-left-div">© British Council {currentYear}</div>
      <div className="footer-right-div">
        <span className="version-link">Version 0.14.9</span>
      </div>
    </footer>
  );
}
