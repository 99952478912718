import { useState, useEffect } from "react";
import ScheduleView from "./ScheduleView.js";
import CentreSelector from "./CentreSelector.js";
import NewModal from "./NewModal";
import DeleteConfirmation from "./DeleteConfirmation.js";
import useTimezone from "../../auth/useTimezone.js";
import dayjs from "dayjs";
import fetchData from "./scripts/fetchData.js";
import "./style/selectors.css";

export default function ScheduleArchive() {
  const [centre, setCentre] = useState();
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const [newModalContent, setNewModalContent] = useState();
  const timezone = useTimezone();

  const getArchivedSchedules = async () => {
    if (centre) {
      let centre_code = centre.centre_code;
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/archived-schedules/${centre_code}`;
      const response = await fetchData(url, "GET");
      const sortedScheduleList = [...response].sort((a, b) =>
        b.update_date > a.update_date ? 1 : -1
      );
      setSchedules(sortedScheduleList);
    }
  };

  const activateSchedule = async (scheduleId) => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    await fetchData(url, "PUT", { is_archive: false });
    getArchivedSchedules();
    setSelectedSchedule("");
  };

  const viewSchedule = async (scheduleId) => {
    setNewModalContent([
      <ScheduleView
        centre={centre}
        scheduleId={scheduleId}
        isLoaded={false}
        loadSchedule={null}
        mergeSchedule={null}
        closeModal={closeModal}
      />,
      true,
    ]);
    setSelectedSchedule(scheduleId);
    setShowNewModal(true);
  };

  function confirmDeleteSchedule(scheduleId) {
    setNewModalContent([
      <DeleteConfirmation
        deleteMessage="Are you sure you want to delete this schedule?"
        deleteFunction={() => deleteSchedule(scheduleId)}
        setShowNewModal={setShowNewModal}
      />,
      false,
    ]);
    setShowNewModal(true);
  }

  const deleteSchedule = async (scheduleId) => {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    await fetchData(url, "DELETE");
    setShowNewModal(false);
    setSelectedSchedule("");
    getArchivedSchedules();
  };

  function closeModal() {
    setShowNewModal(false);
  }

  useEffect(() => {
    getArchivedSchedules();
  }, [centre]);

  const scheduleList = schedules?.map((schedule) => {
    return (
      <tr
        key={schedule._id}
        className={
          schedule._id === selectedSchedule ? "selected-schedule" : undefined
        }
      >
        <td></td>
        <td className="schedule-table-centred-column">{schedule.centre}</td>
        <td>{schedule.schedule_name}</td>
        <td>{schedule.is_archive ? "Archived" : "Working"}</td>
        <td className="centered-column">
          {schedule.schedule_classlist_min_date &&
            dayjs(schedule.schedule_classlist_min_date).format("DD/MM/YYYY")}
        </td>
        <td className="centered-column">
          {schedule.schedule_classlist_min_date &&
            dayjs(schedule.schedule_classlist_max_date).format("DD/MM/YYYY")}
        </td>
        <td className="numeric-column">{schedule.schedule_classlist_count}</td>
        <td className="numeric-column">
          {schedule.schedule_classlist_published}
        </td>
        <td className="centered-column">
          {dayjs(schedule.update_date).tz(timezone).format("DD/MM/YYYY HH:mm")}
        </td>
        <td>{schedule.schedule_author}</td>
        <td className="centered-column">
          {dayjs(schedule.createdAt).tz(timezone).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="schedule-table-centred-column">
          <>
            <button
              className="schedule-list-action-button"
              onClick={(event) => viewSchedule(schedule._id)}
            >
              View
            </button>
            <button
              className="schedule-list-action-button"
              onClick={() => activateSchedule(schedule._id)}
            >
              Activate
            </button>
            <button
              className="schedule-list-action-button"
              onClick={() => confirmDeleteSchedule(schedule._id)}
            >
              Delete
            </button>
          </>
        </td>
      </tr>
    );
  });
  return (
    <>
      <h2>Archived schedules</h2>
      <hr />
      <div className="schedule-container-div">
        <div className="schedule-container-left-div">
          <div className="centre-selector-div">
            <CentreSelector centre={centre} setCentre={setCentre} />
          </div>
        </div>
        <div className="schedule-container-right-div"></div>
      </div>
      <table className="schedule-table">
        <thead>
          <tr>
            <th></th>
            <th>Centre</th>
            <th>Name</th>
            <th>Status</th>
            <th>From</th>
            <th>To</th>
            <th>Classes</th>
            <th>Pub.</th>
            <th>Last updated</th>
            <th>Updated by</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{scheduleList}</tbody>
      </table>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </>
  );
}
