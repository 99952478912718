import { useState, useEffect } from "react";

export default function useTimezone() {
  const [timezone, setTimezone] = useState(() => {
    const centre = JSON.parse(localStorage.getItem("centre"));
    return centre ? centre.time_zone : "UTC";
  });

  useEffect(() => {
    const centre = JSON.parse(localStorage.getItem("centre"));
    if (centre && centre.time_zone !== timezone) {
      setTimezone(centre.time_zone);
    }
  }, []);

  return timezone;
}
